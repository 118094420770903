import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import WebThumbnail from "../../images/WebThumbnail.jpg";

const SEO = ({ lang, title }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const seo = {
    title: title ? title : site.siteMetadata.title,
    description: site.siteMetadata.description,
    url: `${site.siteMetadata.siteUrl}${pathname}`,
    author: site.siteMetadata.author,
    keywords: site.siteMetadata.keywords,
    image: {
      src: WebThumbnail,
      width: 1200,
      height: 650,
    },
  };

  const canonical = pathname ? `${seo.url}${pathname}` : null;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | Home`}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            property: `og:url`,
            content: `${seo.url}`,
          },
          {
            name: `description`,
            content: seo.description,
          },
          {
            name: "keywords",
            content: seo.keywords.join(","),
          },
          {
            property: `og:title`,
            content: seo.title,
          },
          {
            property: `og:description`,
            content: seo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:creator`,
            content: seo.author,
          },
          {
            name: `twitter:title`,
            content: seo.title,
          },
          {
            name: `twitter:description`,
            content: seo.description,
          },
        ].concat([
          {
            property: "og:image",
            content: seo.image.src,
          },
          {
            property: "og:image:width",
            content: seo.image.width,
          },
          {
            property: "og:image:height",
            content: seo.image.height,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
        ])}
      />
    </>
  );
};

export default SEO;
SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
};
SEO.defaultProps = {
  lang: `en`,
  title: "Kunana",
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        author
        keywords
        siteUrl
      }
    }
  }
`;
